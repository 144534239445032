.what-we-do {
  /* width: 100%; */
  margin: 4rem 2rem;
  /* height: 35vh; */
  box-sizing: border-box;
}

.what-we-do h2 {
  color: #000;
  font-family: "Playfair Display", serif;
  font-size: 2.25rem;
  margin-bottom: 2rem;
  /* Add some space below the heading */
}

/* Desktop View Styles */
.desktop-view {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* Align items at the top */
}

.categories {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 3rem;
  text-align: right;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  /* Ensure consistent line height */
  width: 220px;
  /* Adjust width to be wider for consistent spacing */
  min-height: 25rem;
  /* Ensure categories container has a minimum height */
}

.category {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  text-align: right;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  /* Ensure each category takes full width */
  background-clip: padding-box;
  border: 2px solid transparent;
  white-space: nowrap;
  /* Prevent line breaks within categories */
}

.category.active {
  background-color: #ff0017;
  color: #fff;
  border-radius: 10px;
}

.scrollbar {
  position: relative;
  width: 1.4px;
  height: 25rem;
  /* Match the height of the categories */
  background-color: #000;
  margin-left: 1rem;
  /* Space scrollbar from the categories */
}

.scroll-indicator {
  width: 20px;
  height: 20px;
  background-color: #ff0017;
  border-radius: 6px;
  position: absolute;
  left: -9.3px;
  transform-origin: center;
  top: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.content {
  flex-grow: 1;
  margin: 3rem 5rem;
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 1.4;
}

/* Mobile View Styles */
.mobile-view {
  display: none;
  padding: 1rem;
}


.categories-scroll {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 3fr));
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}

.categories-scroll .category {
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  background-color: #fff;
  color: #333;
  font-weight: normal;
  box-sizing: border-box;
}

.category.activeMobile {
  background-color: #ff0017;
  /* Red background for active category */
  color: #fff;
  /* White text for active category */
  font-weight: bold;
}

.content {
  margin: 2rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65%;
  box-sizing: border-box;

}

.content p {
  font-size: 2rem;
  font-family: "Playfair Display";
  color: #000;
}


/* Responsive Styles */
@media (max-width: 1024px) {

  .content {
    margin: 2rem 2rem;
  }

  .content p {
    font-size: 1.5rem;
  }

  .what-we-do {
    height: auto;
  }
}


@media (max-width: 768px) {
  .what-we-do {
    height: auto;
    margin: 2rem 0rem;
  }

  .category {
    text-align: center;
    font-size: larger;
    font-family: "Open Sans", sans-serif;
  }

  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .content p {
    font-size: 1.3rem;
  }
  .mobileContentLine{
    height: 1px;
    margin-bottom: 1rem;
    background-color: black;
  }
  .content {
    margin: auto;
    max-width: 85%;
    text-align: center;
    height: 40vh;
  }
}