.video-masthead {
    position: relative;
    width: 100%;
    height: 55vh; 
    overflow: hidden;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black for tint */
  }
  
  .video-background {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the container */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
  .video-overlay {
    position: absolute;
    bottom: 5rem; /* Adjust as needed for padding from the bottom */
    right: 5rem; /* Adjust as needed for padding from the right */
    color: #FFF;
    font-family: "Open Sans";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Add text shadow for better readability */
    
  }
  
  .video-overlay .highlight {
    color: red;
    font-style: italic;
    font-weight: bold;
  }
  /* Responsive Styles */
  @media (max-width: 768px) {
     .video-masthead{
      height: 35vh;
     }
     .video-overlay {
      font-size: 1.25rem;
      bottom: 2rem;
      right:1rem;
      text-align: right;
    }
  }