/* General Page Layout */
.career-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
}

/* Top Section: Two Columns Layout */
.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .top-section{
    flex-direction: column;
  }
}

/* Left: Title */
.left-content {
  flex: 1;
  min-width: 250px;
  text-align: left;
  max-width: 700px;
}

.left-content h1 {
  font-size: 3rem;
  font-family: "Playfair Display";
  line-height: 1.2;
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .left-content h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
}

/* Right: Apply Button (Circular) */
.right-content {
  flex: 0 0 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.apply-button {
  background-color: #e3000f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  font-size: 2rem;
  font-weight: 800;
  font-family: "Open Sans";
  text-align: center;
  cursor: pointer;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.apply-button:hover {
  transform: scale(1.05);
}

.apply-button span {
  display: block;
  font-size: 2rem;
  margin-bottom: -35px; 
  line-height: 1; /* Set the line-height to be closer */
}

@media (max-width: 768px) {
  .apply-button {
    width: 150px;
    height: 150px;
    font-size: 2rem;
  }
}

/* Middle Section: Paragraph */
.middle-section {
  margin: 20px 0;
  text-align: left;
}

.middle-section p {
  color: var(--Red, #FF0017);
  font-family: "Open Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;  line-height: 1.5;
  border: 1px solid #e3000f;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .middle-section p {
    font-size: 1rem;
  }
}

/* Bottom Section: Job Openings */
.openings-section {
  margin: 40px 0;
}

.openings-section h2 {
  font-size: 3rem;
  margin-bottom: 50px;
  font-family: "Playfair Display";
}

.job-openings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.job-button {
  border: 1px solid #e3000f;
  padding: 15px 30px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
  border-radius: 8px;
  text-decoration: none;
  color: #e3000f;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.job-button:hover {
  background-color: #e3000f;
  color: white;
}

@media (max-width: 768px) {
  .job-button {
    width: 100%;
    text-align: center;
  }
}
