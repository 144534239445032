.team {
  padding: 2rem 0rem;
  text-align: center;
}

.team h2 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 2.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member {
  position: relative;
  flex: 1 1 calc(20% - 1rem);
  max-width: calc(20% - 1rem);
  overflow: hidden;
}

.team-member img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.team-member .member-info {
  position: absolute;
  text-align: left;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  background: rgba(255, 0, 23, 0.8);
  color: #fff;
  opacity: 0;
  flex-wrap: wrap;
  flex-shrink: calc(20% - 1rem);
  transition: opacity 0.3s ease;
  box-sizing: border-box;
}

.team-member:hover .member-info {
  opacity: 1;
}

.member-name {
  font-weight: bold;
  margin: 0;
  color: #FFF;
  font-family: "Open Sans";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 1; /* Allow text to shrink */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* Ensure words break if too long */
}

.member-title {
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 0.5rem;
}


/* Responsive Styles */
@media (max-width: 1200px) {
  .team-member {
    flex: 1 1 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
 
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .team-member {
    flex: 1 1 calc(33.33% - 1rem);
    max-width: calc(33.33% - 1rem);
  }
 
}

@media (max-width: 768px) {
  .team-member {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
  .member-name{
   font-size: 3rem;
  }
  .member-info{
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .team-member {
    flex: 1 1 calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
  .member-name{
    font-size: 3rem;
   }
   .member-info{
     font-size: 2rem;
   }
}