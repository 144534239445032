.footer {
  background-color: #000;
  color: #fff;
  text-align: left;
  padding: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Open Sans";
}

.footer-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-topRow {
  display: flex;
  flex-direction: row;
}

.footer-bottomRow {
  /* display: flex; */
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: space-between;
  font-size: 1.1rem;
}

.footer h1 {
  font-size: 10rem;
  font-weight: bold;
}

.footerText {
  font-size: 1.5rem;
}
.footer-links {
  text-align: right;
  margin-right: 20px; /* Adjust as needed */
}


.logoFooterDiv {
  width: 80px;
  height: 80px;
  background-image: url('../../assets/Martin_M.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



@media (max-width: 1024px) {
  .footer {
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 0rem .5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .footer h1 {
    font-size: 6rem;
  }

}

@media (max-width: 768px) {
  .footer {
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .footer-bottomRow {
    display: flex;
    flex-direction: column;
  }

  .footer-section {
    flex-direction: column;
  }

  .logoFooterDiv {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  h1 {
    font-size: 4rem;
  }

  .smallScreenLogoFooterDiv {
    display: flex;
    width: 50px;
    height: 50px;
    background-image: url('../../assets/Martin_M.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-end;
  }

  .footer-topRow {
    flex-direction: column;
    margin-bottom: 5rem;
    align-items: flex-start;
  }
}