.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.main-content {
  flex: 1; /* This ensures main content takes up all remaining space */
  /* padding: 20px; */
  box-sizing: border-box;
  margin-top: 15vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New layout styles */
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.header {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {

}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .main-content {
    padding: 5px;
  }

  .footer {
    font-size: 0.9rem;
  }
}
