.contact-form {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  
  .input-group {
    position: relative;
    margin-bottom: 20px;
  }
  
  .contact-form input, textarea {
    width: 30rem;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    color: white;
    font-size: 1.5rem;
  }
  
  .contact-form textarea{
    height:10rem;
  }
  .contact-form input::placeholder, textarea::placeholder {
    color: white;
  }
  
  .contact-form label {
    position: absolute;
    top: 20px;
    left: 0;
    color: white;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .contact-form input:focus + label, .contact-form textarea:focus + .contact-form label,
  .contact-form input:not(:placeholder-shown) + .contact-form label,
  .contact-form textarea:not(:placeholder-shown) + .contact-form label {
    transform: translateY(-20px);
    font-size: 12px;
  }
  
  .send-button {
    padding: 10px;
    color: white;
    background-color: black;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-button:hover {
    background-color: #333;
  }
  
@media (max-width: 768px) {
  .contact-form input, textarea {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    color: white;
    font-size: 1.5rem;
    width: auto;
    /* height:5rem; */
  }
  .contact-form textarea{
    height:10rem;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    /* max-width: 00px; */
    margin: 0;

  }
  
}