.about-us {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-us-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    /* Adjust this value based on the desired height */
    margin-bottom: 2rem;
    object-fit: cover;
    /* Ensures the image covers its container */
    object-position: 20% 30%;
    /* Keeps the top of the image in view */
}

.about-us h2 {
    color: #000;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 2rem;
}

.about-us p {
    color: #000;
    font-family: "Open Sans";
    font-size: 1.1rem;
    width: 75%;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    
}

/* Responsive Styles */
@media (max-width: 1500px) {
   
    .about-us h2 {
        font-size: 2rem;
    }

    /* .about-us p {
        font-size: 2rem;
        padding: 0rem 1rem;
    } */
  }

/* Responsive Styles */
@media (max-width: 768px) {
    .about-us {
    }

    .about-us h2 {
        font-size: 1.75rem;
    }

    .about-us p {
        font-size: 1.2rem;
        width: 80%;
        text-align: justify;
        /* padding: 0rem 2rem; */
    }
}