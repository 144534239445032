.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
}

.privacy-container h1, h2 {
    color: #333;
}

.privacy-container ul {
    padding-left: 20px;
}

.privacy-container p {
    margin-bottom: 15px;
}

.privacy-container a {
    color: #007bff;
    text-decoration: none;
}

.privacy-container a:hover {
    text-decoration: underline;
}
