.autoPageContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', serif;
}

/* Title */
.autoPageTitle {
  width: 80%;
  margin: 2rem auto;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Playfair Display', serif;
  color: #000;
}

/* Row Container */
.rowContainer {
  display: flex;
  /* width: 80%; */
  margin: 2rem auto;
  gap: 2rem; /* space between columns */
  align-items: center;
  justify-content: space-between;
}

.imageCol,
.textCol {
  flex: 1;
  min-width: 0;
}
.textCol{
  padding: 2rem;
}

.autoImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Gray Background */
.grayBackground {
  background-color: #E2E2E2;
  padding: 2rem 0;
}

.inlineTitle {
  display: inline;
  font-family: 'Playfair Display', serif;
  font-size: 2rem;       /* match your heading size */
  font-weight: 700;        /* or 700 if you want bolder */
  color: #000;
  margin-right: 0.5rem;    /* small gap after the “title” text */
}


/* Text Column Headings */
.textCol h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #000;
  margin-bottom: 0.5rem;
}

/* Text Column Paragraphs */
.textCol p {
  font-size: 1.2rem;
  font-family: "Open Sans";
  line-height: 1.6;
  color: #000;
  margin-bottom: 1.5rem;
}

/* Lists in Text Column */
.textCol ul {
  margin-left: 2rem;
  margin-bottom: 1.5rem;
}
.textCol li {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
/****************************************************/
/*  Digital Media Philosophy (Single Column)        */
/****************************************************/
.philosophySection {
  width: 60%;
  margin: 2rem auto;
  background-color: #fff; /* or transparent, if desired */
}

.inlineTitle {
  display: inline; 
  font-family: 'Playfair Display', serif;
  font-size: 2.3rem;
  font-weight: 600;
  color: #000;
  margin-right: 0.5rem;
}

.philosophySection p {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #000;
}


/****************************************************/
/*  Creative Services (Two Column, Gray BG)         */
/****************************************************/
.creativeServicesSection {
  padding: 2rem 0;
}

.grayBackground {
  background-color: #E2E2E2;
}

/* Wrapper to constrain width */
.innerWrapper {
  width: 80%;
  margin: 0 auto;
}

/* Heading styles */
.creativeServicesSection h2 {
  margin: 0 0 1rem;
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #000;
}

/* Two-column row */
.creativeRow {
  display: flex;
  align-items: flex-start; /* Top-aligned columns */
  gap: 2rem;
}

/* Columns */
.leftCol,
.rightCol {
  flex: 1;
  min-width: 0;
}

/* Paragraph text */
.leftCol p,
.rightCol p {
  margin: 0; /* Remove default <p> top margin */
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000;
}



/* Responsive for Mobile */
@media (max-width: 768px) {
  .autoPageTitle {
    width: 90%;
    font-size: 2rem;
  }

  .rowContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .imageCol,
  .textCol {
    flex: none;
    width: 90%;
  }

  .textCol h2 {
    font-size: 1.5rem;
  }

  .textCol p,
  .textCol li {
    font-size: 1rem;
  }

  .philosophySection, .creativeRow {
    width: 90%;
  }

  .creativeRow {
    flex-direction: column;
    gap: 1rem;
  }

  .inlineTitle {
    font-size: 1.5rem;
  }

  .leftCol h2 {
    font-size: 1.8rem;
  }

  .leftCol p, 
  .rightCol p {
    font-size: 1rem;
  }
}
