.clients-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 logos per row on larger screens */
    gap: 2rem; /* Space between logos */
    padding: 2rem;
    justify-items: center; /* Center items within grid cells */
  }
  
  .clients-title {
    color: #000;
    font-family: "Playfair Display", serif; /* Ensure font family is correct */
    font-size: 2.25rem;
  }
  
  .client-logo {
    width: 200px; /* Increased width for logos */
    height: 200px; /* Increased height for logos */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .client-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Responsive design for medium screens */
  @media (max-width: 1024px) {
    .clients-container {
      grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
    }
  
    .client-logo {
      width: 180px; /* Slightly reduced size for medium screens */
      height: 180px;
    }
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .clients-container {
      grid-template-columns: repeat(2, 1fr); /* 2 logos per row on smaller screens */
      padding: 0;
    }
  
    .client-logo {
      width: 160px; /* Adjusted size for smaller screens */
      height: 160px;
    }
  }
  